import React from "react"
import Layout from "../../components/Layout/Layout"
import PageHeader from "../../components/shared/PageHeader"
import TextBlock from "../../components/Education/TextBlock"
import {graphql, StaticQuery} from "gatsby"

const EducationPage = (props) => (
    <Layout title="Education Resources | London | Back to the Future the Musical" description="Education Resources - The official place to buy tickets for Back To The Future The Musical. Now playing at the Adelphi Theatre in London.">
        <PageHeader
            imageXs={props.data.imageXs}
            imageSm={props.data.imageSm}
            imageLg={props.data.imageLg}
            title="Education Resources" />
        <TextBlock/>
    </Layout>
)

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                imageXs: file(relativePath: { eq: "576.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 575,
                            quality: 100
                        )
                    }
                }
                imageSm: file(relativePath: { eq: "991.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 991,
                            quality: 100
                        )
                    }
                }
                imageLg: file(relativePath: { eq: "1920.png" }) {
                    childImageSharp {
                         gatsbyImageData(
                            width: 1740,
                            quality: 100
                        )
                    }
                }
            }
		`}
        render={data => (
            <EducationPage
                {...props}
                data={data}/>
        )}
    />
)
